import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getCondicionesDePago } from '../../../services/CondicionesDePagoYEntrega/getCondicionesDePago';
import { globalContext } from '../../Context/GlobalContext';
import { actualizarCondicionesDePago } from '../../../utilities/handlers/handleActualizarCondicionesDePago';
import { authContext } from '../../Context/AuthContext';
import { cartContext } from '../../Context/CartContext';
import { MdOutlinePayment } from "react-icons/md";
import { Form } from 'react-bootstrap';

const PagoOptions = () => {
    const [t] = useTranslation('global');
    const { pago,
        setPago,
        condPago,
        setCondPago,
        info,
        tiposDeEntrega,
        infoInputs,
        esPrePay,
        setEsPrepay,
        esPickup,
    } = useContext(globalContext)
    const [actualizando, setActualizando] = useState(false);
    const [precompra, setPrecompra] = useState(JSON.parse(localStorage.getItem('precompra')))
    const { token, user } = useContext(authContext)
    const [showFileInput, setShowFileInput] = useState(false);
    const { setSelectedFile } = useContext(cartContext);
    const [fileUpdated, setFileUpdated] = useState(false);


    const country = process.env.REACT_APP_COUNTRY;

    const fetchCondicionesPago = async () => {
        const storedCondPago = localStorage.getItem('condPago');
        if (storedCondPago) {
            setCondPago(JSON.parse(storedCondPago));
        } else {
            try {
                const data = await getCondicionesDePago();
                setCondPago(data);
                localStorage.setItem('condPago', JSON.stringify(data));
            } catch (error) {
                console.log(error)
            }
        }
    };

    useEffect(() => {
        fetchCondicionesPago();
    }, []);

    const handleActualizarPago = async () => {
        await actualizarCondicionesDePago(info, condPago, precompra, setPrecompra, token, user, setActualizando);
    };

    useEffect(() => {
        handleActualizarPago();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [info.entrega, info.pago]);

    const handlePagoChange = (e) => {
        const value = e.target.value;
        const selectedPago = condPago.find(pay => pay.Descripcion === value);
        console.log(selectedPago, "selectedpago")
        setPago(value);
        setShowFileInput(info.pago === 'PRE PAY' && info.entrega === "RETIRA");
        infoInputs({ target: { name: 'pago', value } });

        if (value !== '02') {
            setSelectedFile(null);
            setFileUpdated(false);
        }

        if (country === "USA" && selectedPago && selectedPago.Codigo === "02") {
            setEsPrepay(true);
        } else {
            setEsPrepay(false);
        }
    };

    console.log(pago, "este es pago")
    console.log(condPago, "condpago")

    const handleFileChange = (e) => {
        const file = e.target.files[0];

        if (file) {
            setFileUpdated(true);

            const fileData = {
                name: file.name,
                type: file.type,
                size: file.size,
                content: ''
            };

            const reader = new FileReader();
            reader.onload = () => {
                const base64Content = reader.result.split(',')[1];
                fileData.content = base64Content;
                setSelectedFile(fileData);
            };
            reader.readAsDataURL(file);
        } else {
            setSelectedFile(null);
            setFileUpdated(false);
        }
    };

    useEffect(() => {
        if (info.pago !== '02') {
            setFileUpdated(false);
        }
    }, [info.pago]);

    return (
        <div style={{ flex: '1' }}>
            <div style={{ flex: '1' }}>
                <Form.Group controlId='pago'>
                    <Form.Label style={{ fontWeight: 'bold', fontSize: '1.5rem', color: '#475569' }}>
                        <MdOutlinePayment style={{ fontSize: '2.5rem', color: 'red', marginRight: '5px' }} />
                        {t('facturacion.opciones-pago')}
                    </Form.Label>
                    <Form.Control
                        as='select'
                        value={pago}
                        name='pago'
                        onChange={handlePagoChange}
                    >
                        <option value="">Seleccione...</option>
                        {condPago.map((condicion) => (
                            <option key={condicion.Codigo} value={condicion.Descripcion}>
                                {condicion.Descripcion}
                            </option>
                        ))}
                    </Form.Control>
                </Form.Group>
            </div>
            {country === "UY" && (
                <div style={{ textAlign: 'center' }}>
                    <Form.Label
                        htmlFor="archivoTransferencia"
                        style={{
                            fontWeight: 'bold',
                            marginBottom: '10px',
                            display: 'block',
                            margin: '0 auto',
                        }}
                    >
                        *Por favor, adjunte comprobante de transferencia
                    </Form.Label>
                    <input
                        type="file"
                        name="archivoTransferencia"
                        id="archivoTransferencia"
                        onChange={handleFileChange}
                        accept=".jpg, .png, .pdf"
                        style={{
                            padding: '10px',
                            border: '1px solid #ccc',
                            borderRadius: '5px',
                            cursor: 'pointer',
                            width: '100%',
                            maxWidth: '300px',
                            textAlign: 'center',
                        }}
                    />
                </div>
            )}

            {info.entrega === "RETIRA" && info.pago === "PRE PAY" && country !== "UY" && (
                <div style={{ display: 'flex', alignItems: 'center', margin: '20px 0' }}>
                    <label htmlFor='archivoTransferencia' style={{ fontWeight: 'bold', marginBottom: '10px' }}>
                        Por favor, adjunte comprobante de transferencia
                    </label>
                    <input
                        type='file'
                        name='archivoTransferencia'
                        id='archivoTransferencia'
                        onChange={handleFileChange}
                        accept='.jpg, .png, .pdf'
                        style={{
                            padding: '10px',
                            border: '1px solid #ccc',
                            borderRadius: '5px',
                            cursor: 'pointer',
                            width: '100%',
                            maxWidth: '300px',
                            textAlign: 'center'
                        }}
                    />
                </div>
            )}

            {esPickup && esPrePay && country === "USA" && (
                <div style={{ display: 'flex', alignItems: 'center', margin: '20px 0' }}>
                    <label htmlFor='archivoTransferencia' style={{ fontWeight: 'bold', marginBottom: '10px' }}>
                        Por favor, adjunte comprobante de transferencia
                    </label>
                    <input
                        type='file'
                        name='archivoTransferencia'
                        id='archivoTransferencia'
                        onChange={handleFileChange}
                        accept='.jpg, .png, .pdf'
                        style={{
                            padding: '10px',
                            border: '1px solid #ccc',
                            borderRadius: '5px',
                            cursor: 'pointer',
                            width: '100%',
                            maxWidth: '300px',
                            textAlign: 'center'
                        }}
                    />
                </div>
            )}
        </div>
    );
};

export default PagoOptions;
